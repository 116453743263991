import Logo from "./logo";
import "./App.css";
import "@fontsource/gloria-hallelujah";
import "@fontsource/gloock";

function App() {
  return (
    <div className="container">
      <Logo width="20vh" />
      <div className="content">
        <h1 className="title">Saur macht lustig</h1>
        <h2 className="subtitle">Homemade goods. Crafted with ♥️</h2>
        <p className="description">
          Ob Kräuterlimonade, Pflegelotion oder leckerer Senf. Wir stellen alle
          unserer hochqualitativen Produkte mit eigenständig und mit größter
          Sorgfalt her.
        </p>
        <button className="cta-button">Ich möchte mehr davon</button>
      </div>
    </div>
  );
}

export default App;
