import * as React from "react";
const Logo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeMiterlimit: 1.5,
    }}
    viewBox="0 0 577 618"
    {...props}
  >
    <path
      d="M0 0h576.31v617.239H0z"
      style={{
        fill: "none",
      }}
    />
    <path
      d="M0 255.819 258.954 11.355S267.637 0 288.155 0c19.149 0 29.201 11.355 29.201 11.355L576.31 255.819v361.42H0v-361.42Z"
      style={{
        fill: "url(#a)",
      }}
    />
    <path
      d="M200.918 269.818v72.887a6.17 6.17 0 0 1-6.167 6.167 6.17 6.17 0 0 1-6.167-6.167v-72.887a6.17 6.17 0 0 1 6.167-6.168 6.17 6.17 0 0 1 6.167 6.168ZM387.727 269.818v72.887a6.17 6.17 0 0 1-6.168 6.167 6.17 6.17 0 0 1-6.167-6.167v-72.887a6.17 6.17 0 0 1 6.167-6.168 6.171 6.171 0 0 1 6.168 6.168ZM288.155 412.796H261.71a6.17 6.17 0 0 1-6.167-6.168 6.17 6.17 0 0 1 6.167-6.167h20.278v-66.72a6.17 6.17 0 0 1 6.167-6.167 6.17 6.17 0 0 1 6.167 6.167v72.887a6.17 6.17 0 0 1-6.167 6.168Z"
      style={{
        fill: "#29bab9",
      }}
    />
    <path
      d="M97.553 414.425s77.286 99.957 190.602 99.472c137.448 0 190.602-101.101 190.602-101.101"
      style={{
        fill: "none",
        stroke: "#29bab9",
        strokeWidth: 12,
      }}
    />
    <defs>
      <radialGradient
        id="a"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="rotate(90 -7.149 301.471) scale(540.221)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#ebebeb",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.52}
          style={{
            stopColor: "#dadada",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#a3a3a3",
            stopOpacity: 1,
          }}
        />
      </radialGradient>
    </defs>
  </svg>
);

export default Logo;
